'use strict'

angular
.module('cb.directives', [])
.directive('cbActivityLogModal', function () {
    return {
        restrict: 'E',
        replace: true,
        transclude: true,
        scope: {
            Id: '=infoId',
        },
        templateUrl: 'views/templates/activity-modal-link.html',
        controller: "ActivityModalLinkController",
    }
})
.directive('cbReportPaging', function () {
    return {
        restrict: 'A',
        replace: true,
        transclude: true,
        scope: {
            pageNumber: '=infoPageNumber',
            listLength: '=infoListLength',
            listLimit: '=infoListLimit'
        },
        templateUrl: 'templates/report-paging.html',
        controller: "ReportPagingController",
    }
})
.directive('cbPagination', function () {
    return {
        restrict: 'A',
        replace: true,
        transclude: true,
        scope: {
            pageNumber: '=infoPageNumber',
            pagination: '=infoPagination'
        },
        templateUrl: 'templates/pagination-paging.html',
        controller: "CBPaginationController",
    }
})